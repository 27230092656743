<template>
  <div class="info" id="watermark">
    <top-bar :title="'场所详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" >
      <van-cascader
          :field-names="fieldNames"
          v-model="cascaderValue"
          title="请选择"
          :options="list.placeTypeList"
          @close="typeShow = false"
          @change="typeConfirm"
      />
    </van-popup>

    <van-popup
        v-model="subareaShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.orgList"
          value-key="label"
          @confirm="subareaConfirm"
          @cancel="subareaShow = false"
      />
    </van-popup>

    <!-- 选择地址 -->
    <van-popup v-model="buildingShow" position="bottom" @click-overlay="buildingOverlay">
      <van-cascader v-model="dataForm.building" title="请选择" :options="list.buildingList"
                    @close="buildingShow = false" @change="buildingFinish" :field-names="fieldNames"/>
    </van-popup>

    <van-popup v-model="categoryShow" position="bottom" >
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.categoryList"
          value-key="label"
          @confirm="categoryConfirm"
          @cancel="categoryShow = false"
      />
    </van-popup>

    <van-popup
        v-model="importantTypeShow"
        position="bottom">
      <van-picker
          v-model="importantTypeShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.importantTypeList"
          @cancel="importantTypeShow = false"
          @confirm="importantTypeConfirm"
      />
    </van-popup>

    <van-popup v-model="label" position="bottom" @click-overlay="labelOverlay">
      <van-cascader
          title="请选择"
          value="value"
          :field-names="{ text: 'label', value: 'value', children: 'children' }"
          :options="list.userCommunityLabelTree"
          active-color="#1989fa"
          @close="labelClose"
          @change="labelFinish"
      />
    </van-popup>

    <van-popup
        v-model="safetyPerilShow"
        position="bottom">
      <van-picker
          v-model="safetyPerilShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.safetyPerilList"
          @cancel="safetyPerilShow = false"
          @confirm="safetyPerilConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasHygieneShow"
        position="bottom">
      <van-picker
          v-model="hasHygieneShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasFoodList"
          @cancel="hasHygieneShow = false"
          @confirm="hasHygieneConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasFoodShow"
        position="bottom">
      <van-picker
          v-model="hasFoodShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasFoodList"
          @cancel="hasFoodShow = false"
          @confirm="hasFoodConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasMedicalShow"
        position="bottom">
      <van-picker
          v-model="hasMedicalShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasMedicalList"
          @cancel="hasMedicalShow = false"
          @confirm="hasMedicalConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasDrugShow"
        position="bottom">
      <van-picker
          v-model="hasDrugShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasDrugList"
          @cancel="hasDrugShow = false"
          @confirm="hasDrugConfirm"
      />
    </van-popup>

    <van-popup v-model="operateBeginShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.operateBeginTime" @confirm="operateBeginConfim" @cancel="operateBeginShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="operateEndShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.operateEndTime" @confirm="operateEndConfim" @cancel="operateEndShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="registerShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.registerTime" @confirm="registerConfim" @cancel="registerShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="moneySourceShow"
        position="bottom">
      <van-picker
          v-model="moneySourceShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.moneySourceList"
          @cancel="moneySourceShow = false"
          @confirm="moneySourceConfirm"
      />
    </van-popup>

    <van-popup v-model="payCheckShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.payCheck" @confirm="payCheckConfim" @cancel="payCheckShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="payerStatusShow"
        position="bottom">
      <van-picker
          v-model="payerStatusShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.payerStatusList"
          @cancel="payerStatusShow = false"
          @confirm="payerStatusConfirm"
      />
    </van-popup>

    <van-popup v-model="payCancelShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.payCancel" @confirm="payCancelConfim" @cancel="payCancelShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="signCancelShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.signCancelTime" @confirm="signCancelConfim" @cancel="signCancelShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="riskLevelShow"
        position="bottom">
      <van-picker
          v-model="riskLevelShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.riskLevelList"
          @cancel="riskLevelShow = false"
          @confirm="riskLevelConfirm"
      />
    </van-popup>

    <van-popup v-model="insuredStageShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.insuredStage" @confirm="insuredStageConfim" @cancel="insuredStageShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="insuredStatusShow"
        position="bottom">
      <van-picker
          v-model="insuredStatusShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.insuredStatusList"
          @cancel="insuredStatusShow = false"
          @confirm="insuredStatusConfirm"
      />
    </van-popup>

    <van-popup
        v-model="codeShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.codeList"
          value-key="label"
          @confirm="codeConfirm"
          @cancel="codeShow = false"
      />
    </van-popup>

    <div class="border-content" v-show="!gridShow">
      <template v-if="changeType == 1">
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">基础信息</span>
              <van-button type="info" size="small" round @click="disable1=!disable1" v-if="disable1 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable1 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell title="所属机构" :value="selectMsg.subareaStr" @click="disable1 ? (subareaShow = false) : (subareaShow = true)"
                    :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="!disable1"/>
          <van-cell title="所属楼幢房屋" :value="selectMsg.buildingStr" @click="disable1 ? (buildingShow = false) : (buildingShow = true)"
                    :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="!disable1"/>
          <van-cell title="常用名称" required>
            <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.name" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="营业执照名称">
            <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.licenseName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="统一社会信用代码" class="licenseCode">
            <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.licenseCode" @blur="getInfoByLicenseCode" laceholder="请输入" style="text-align: right">
              <template #right-icon>
                <van-uploader :after-read="cardIdIdentified" :disabled="disable1">
                  <van-icon size="25" color="#BBB" name="photograph"/>
                </van-uploader>
              </template>
            </van-field>
          </van-cell>
          <van-cell title="经营类型" :value="selectMsg.placeTypeStr" @click="disable1 ? (typeShow = false) : (typeShow = true)"
                    :value-class="{'value-common':selectMsg.placeTypeStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="店铺类型" :value="selectMsg.categoryStr" @click="disable1 ? (categoryShow = false) : (categoryShow = true)"
                    :value-class="{'value-common':selectMsg.categoryStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="面积">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.areas" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="职工人数">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.staffNum" placeholder="请输入" type="digit" style="text-align: right"/>
          </van-cell>
          <van-cell title="主管单位">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.competentUnit" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="经营范围" :border="false" class="cellClass" required />
          <van-field type="textarea" clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.content" placeholder="请输入" style="text-align: right" rows="1" autosize/>
          <template v-if="isCodeShow">
            <van-cell title="标准地址名称">
              <van-field clearable type="textarea" autosize rows="1" clear-trigger="always" :readonly="disable1" v-model="selectMsg.codeName"
                         @blur="dataForm.address = dataForm.codeName" placeholder="请输入" style="text-align: right"/>
            </van-cell>
            <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
            <van-cell title="标准地址码" :value="selectMsg.codeStr || '请选择'" @click="disable1 ? false : getCode()"
                      :value-class="{'value-common':selectMsg.codeStr=='请选择'}" :is-link="!disable1"/>
          </template>
          <van-cell title="详细地址" :border="false" class="cellClass" required />
          <van-field type="textarea" clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.address" placeholder="请输入" style="text-align: right" rows="1" autosize/>
          <van-cell title="备注" :border="false" class="cellClass"/>
          <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.remark" placeholder="请输入" style="text-align: right"/>

        </van-cell-group>
        <!-- 自定义字段 -->
        <field-view ref="fieldView" @fatherChange="disable2Change" :disabled="disable2" :id="dataForm.id"  @submit="submit"/>
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">标签信息</span>
              <van-button type="info" size="small" round @click="disable3=!disable3" v-if="disable3 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable3 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell
              readonly
              clickable
              name="标签管理"
              :value="selectMsg.labelStr || '请选择'"
              title="标签管理"
              :is-link="!disable3"
              @click="showlabel"
          />
          <van-popup v-model="label" position="bottom" @click-overlay="labelOverlay">
            <van-cascader
                title="请选择"
                value="value"
                :field-names="{ text: 'label', value: 'value', children: 'children' }"
                :options="list.userCommunityLabelTree"
                active-color="#1989fa"
                @close="labelClose"
                @change="labelFinish"
            />
          </van-popup>

          <van-cell hover-class="none" v-if="labels.length>0">
            <template v-for="item in labels">
              <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                <span :style="disable3 ? 'border: none' : ''">{{ item.name }}</span>
                <van-icon style="padding: 0 10px" name="cross" v-if="!disable3" @click="removeLabel(item.value)"/>
              </div>
            </template>
          </van-cell>
          <van-row class="uploadBox">
            <van-col :span="24">
              <van-uploader
                  v-model="urls"
                  :readonly="disable3"
                  @delete="uploaddel"
                  :after-read="uploadRead"
                  :deletable="!disable3"
                  :show-upload="!disable3"
              />
            </van-col>
          </van-row>
        </van-cell-group>
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">其他信息</span>
              <van-button type="info" size="small" round @click="disable4=!disable4" v-if="disable4 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable4 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell title="安全隐患类型" :value="selectMsg.safetyPerilStr || '请选择'" @click="disable4 ? (safetyPerilShow = false) : (safetyPerilShow = true)"
                    :value-class="{'value-common':selectMsg.safetyPerilStr=='请选择'}" :is-link="!disable4"/>
          <van-cell title="灭火器数量">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.fireNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="煤气瓶数量">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.gasNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否有卫生许可证" :value="selectMsg.hasHygieneStr || '请选择'" @click="disable4 ? (hasHygieneShow = false) : (hasHygieneShow = true)"
                    :value-class="{'value-common':selectMsg.hasHygieneStr=='请选择'}" :is-link="!disable4"/>
          <van-cell title="卫生许可证号码" v-if="dataForm.hasHygiene == 0">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.hygieneCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否有食品经营许可证" :value="selectMsg.hasFoodStr || '请选择'" @click="disable4 ? (hasFoodShow = false) : (hasFoodShow = true)"
                    :value-class="{'value-common':selectMsg.hasFoodStr=='请选择'}" :is-link="!disable4" />
          <van-cell title="是否有医疗器械经营许可证" :value="selectMsg.hasMedicalStr || '请选择'" @click="disable4 ? (hasMedicalShow = false) : (hasMedicalShow = true)"
                    :value-class="{'value-common':selectMsg.hasMedicalStr=='请选择'}" :is-link="!disable4"/>
          <van-cell title="是否有药品经营许可证" :value="selectMsg.hasDrugStr || '请选择'" @click="disable4 ? (hasDrugShow = false) : (hasDrugShow = true)"
                    :value-class="{'value-common':selectMsg.hasDrugStr=='请选择'}" :is-link="!disable4"/>

        </van-cell-group>
      </template>

      <van-cell-group class="info-second" v-if="changeType == 2">
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">联络信息</span>
            <van-button type="info" size="small" round @click="disable5=!disable5" v-if="disable5 == true">编辑</van-button>
            <van-button type="info" size="small" round @click="submit" v-if="disable5 == false">保存</van-button>
          </template>
        </van-cell>
        <van-cell title="主要联系人" required>
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.contact" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="联系人电话" required>
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.contactMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人">
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.legal" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人电话">
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.legalMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </van-cell-group>

      <van-cell-group v-if="!!dataForm.gridId && changeType == 3">
        <div class="placeInfo" v-if="codeType == 2">
          <div class="top" v-if="selectMsg.codeMappingEntity">
            <p><span>{{selectMsg.communityName}}</span><span v-if="selectMsg.subareaName">{{'-' + selectMsg.subareaName}}</span><span v-if="selectMsg.buildingName">{{'-' + selectMsg.buildingName + '幢'}}</span></p>
            <van-row class="content">
              <van-col :span="24">地址信息</van-col>
              <van-col :span="24">街路巷弄：{{selectMsg.codeMappingEntity.road}}</van-col>
              <van-col :span="24">门牌号：{{selectMsg.codeMappingEntity.doorNumber}}</van-col>
              <van-col :span="24">院落名：{{selectMsg.codeMappingEntity.subarea}}</van-col>
              <van-col :span="24">地址编码：{{selectMsg.codeMappingEntity.subarea}}</van-col>
            </van-row>
          </div>
          <div class="top topInfo" v-if="!selectMsg.codeMappingEntity">
            <p><span>{{selectMsg.communityName}}</span><span v-if="selectMsg.subareaName">{{'-' + selectMsg.subareaName}}</span><span v-if="selectMsg.buildingName">{{'-' + selectMsg.buildingName}}</span></p>
          </div>

          <div class="bottom" v-if="selectMsg.codeMappingEntity">
            <!--          <van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 2px;padding: 3px 5px">空</van-tag>-->
            <span class="grid">所属网格：<i>{{selectMsg.gridName}}</i></span>
          </div>
          <div class="bottomInfo" v-if="!selectMsg.codeMappingEntity">
            <span class="grid">所属网格：<i>{{selectMsg.gridName}}</i></span>
          </div>
        </div>
        <van-cell class="custom" style="margin-top: 10px;">
          <template #title>
            <span class="custom-title">网格信息</span>
            <van-button type="info" size="small" round @click.self="disable6=!disable6" v-if="disable6 == true">编辑</van-button>
            <van-button type="info" size="small" round @click.self="submit" v-if="disable6 == false">保存</van-button>
          </template>
        </van-cell>
        <van-cell title="所属网格" class="gridBtn" :value="selectMsg.gridName || '请选择'" @click="disable6 ? (gridShow = false) : (gridShow = true)"
                  :value-class="{'value-common':selectMsg.gridName=='请选择'}" :is-link="!disable6">
          <!--        <template>-->
          <!--          <van-button type="info" size="small" round @click.self="disable6=!disable6" v-if="disable6 == true">编辑</van-button>-->
          <!--          <van-button type="info" size="small" round @click.self="submit" v-if="disable6 == false">保存</van-button>-->
          <!--        </template>-->
        </van-cell>
        <van-cell :title="item.postStr || item.managerTypeStr" class="gridUser" v-for="(item, index) in selectMsg.gridUsers" :key="index">
          <van-field clearable clear-trigger="always" readonly v-model="item.name || '无'" placeholder="请输入" style="text-align: right">
            <template #right-icon>
              <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/mobile.png" alt="" class="tel"/></a>
            </template>
          </van-field>
        </van-cell>
      </van-cell-group>

      <van-cell-group class="info-second" v-if="changeType === 4">
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">注册登记信息</span>
            <van-button type="info" size="small" round @click="disable7=!disable7" v-if="disable7 == true">编辑</van-button>
            <van-button type="info" size="small" round @click="submitExtra" v-if="disable7 == false">保存</van-button>
          </template>
        </van-cell>
        <van-cell title="注册地址" :border="false" class="cellClass" />
        <van-field type="textarea" clearable clear-trigger="always" :readonly="disable7" v-model="extraInfo.address" placeholder="请输入" style="text-align: right" rows="1" autosize/>
        <van-cell title="开办资金">
          <van-field clearable clear-trigger="always" :readonly="disable7" v-model="extraInfo.amount" placeholder="请输入" type="digit" style="text-align: right"/>
        </van-cell>
        <van-cell title="经营（驻在）开始期限" :value="extraInfo.operateBeginTime || '请选择'" @click="disable7 ? (operateBeginShow = false) : (operateBeginShow = true)"
                  :value-class="{'value-common':!extraInfo.operateBeginTime}" :is-link="!disable7" />
        <van-cell title="经营（驻在）结束期限" :value="extraInfo.operateEndTime || '请选择'" @click="disable7 ? (operateEndShow = false) : (operateEndShow = true)"
                  :value-class="{'value-common':!extraInfo.operateEndTime}" :is-link="!disable7" />
        <van-cell title="注册（登记）日期" :value="extraInfo.registerTime || '请选择'" @click="disable7 ? (registerShow = false) : (registerShow = true)"
                  :value-class="{'value-common':!extraInfo.registerTime}" :is-link="!disable7"/>
        <van-cell title="注册登记号">
          <van-field clearable clear-trigger="always" :readonly="disable7" v-model="extraInfo.registerCode" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="经济来源" :value="selectMsg.moneySourceStr || '请选择'" @click="disable7 ? (moneySourceShow = false) : (moneySourceShow = true)"
                  :value-class="{'value-common':selectMsg.moneySourceStr=='请选择'}" :is-link="!disable7" />
        <van-cell title="登记机关名称">
          <van-field clearable clear-trigger="always" :readonly="disable7" v-model="extraInfo.officeName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </van-cell-group>

      <van-cell-group class="info-second" v-if="changeType == 5">
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">税务信息</span>
            <van-button type="info" size="small" round @click="disable8=!disable8" v-if="disable8 == true">编辑</van-button>
            <van-button type="info" size="small" round @click="submitExtra" v-if="disable8 == false">保存</van-button>
          </template>
        </van-cell>
        <van-cell title="纳税人名称">
          <van-field clearable clear-trigger="always" :readonly="disable8" v-model="extraInfo.payer" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="纳税人识别号">
          <van-field clearable clear-trigger="always" :readonly="disable8" v-model="extraInfo.payerCode" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="税务登记日期" :value="extraInfo.payCheck || '请选择'" @click="disable8 ? (payCheckShow = false) : (payCheckShow = true)"
                  :value-class="{'value-common':!extraInfo.payCheck}" :is-link="!disable8" />
        <van-cell title="税务登记机关">
          <van-field clearable clear-trigger="always" :readonly="disable8" v-model="extraInfo.payOffice" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="纳税人状态" :value="selectMsg.payerStatusStr || '请选择'" @click="disable8 ? (payerStatusShow = false) : (payerStatusShow = true)"
                  :value-class="{'value-common':selectMsg.payerStatusStr=='请选择'}" :is-link="!disable8" />
        <van-cell title="税务注销日期" :value="extraInfo.payCancel || '请选择'" @click="disable8 ? (payCancelShow = false) : (payCancelShow = true)"
                  :value-class="{'value-common':!extraInfo.payCancel}" :is-link="!disable8" />
      </van-cell-group>

      <van-cell-group class="info-second" v-if="changeType == 6">
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">参保信息</span>
            <van-button type="info" size="small" round @click="disable9=!disable9" v-if="disable9 == true">编辑</van-button>
            <van-button type="info" size="small" round @click="submitExtra" v-if="disable9 == false">保存</van-button>
          </template>
        </van-cell>
        <van-cell title="参保单位名称">
          <van-field clearable clear-trigger="always" :readonly="disable9" v-model="extraInfo.insuredName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="参保单位编号">
          <van-field clearable clear-trigger="always" :readonly="disable9" v-model="extraInfo.insuredCode" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="社保行业风险类别" :value="selectMsg.riskLevel || '请选择'"  @click="disable9 ? (riskLevelShow = false) : (riskLevelShow = true)"
                  :value-class="{'value-common':selectMsg.riskLevel=='请选择'}" :is-link="!disable9" />
        <van-cell title="参保缴费期别" :value="extraInfo.insuredStage || '请选择'" @click="disable9 ? (insuredStageShow = false) : (insuredStageShow = true)"
                  :value-class="{'value-common':!extraInfo.insuredStage}" :is-link="!disable9"/>
        <van-cell title="参保缴费状态" :value="selectMsg.insuredStatus || '请选择'"  @click="disable9 ? (insuredStatusShow = false) : (insuredStatusShow = true)"
                  :value-class="{'value-common':selectMsg.insuredStatus=='请选择'}" :is-link="!disable9" />
        <van-cell title="保险管理机关">
          <van-field clearable clear-trigger="always" :readonly="disable9" v-model="extraInfo.manageOffice" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </van-cell-group>

      <van-cell-group class="info-second" v-if="changeType == 7">
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">变更信息</span>
            <van-button type="info" size="small" round @click="disable10=!disable10" v-if="disable10 == true">编辑</van-button>
            <van-button type="info" size="small" round @click="submitChange" v-if="disable10 == false">保存</van-button>
          </template>
        </van-cell>
        <div v-for="(item,index) in changeList" :key="index">
          <van-cell>
            <template #title>
              <span class="form-title-view secondTitle">变更信息{{index + 1}}</span>
              <div class="button-right" v-if="!disable10">
                <van-button plain size="small" :disabled="changeList.length == 1" @click="deleteClick(item.id, index)" style="background-color:transparent; color: #E9564B;border: 1px solid #E9564B;margin-right: 20px;">删除</van-button>
                <van-button plain size="small" v-if="index == 0" @click="addInfo">新增</van-button>
              </div>
            </template>
          </van-cell>
          <van-cell title="变更日期" :value="item.changeDate || '请选择'" @click="disable10 ? (item.changeDateShow = false) : (item.changeDateShow = true)"
                    :value-class="{'value-common':!item.changeDate}" :is-link="!disable10" />
          <van-popup v-model="item.changeDateShow" position="bottom"  >
            <van-datetime-picker @confirm="changeDateConfim($event, index)" @cancel="item.changeDateShow = false"  type="date" title="选择年月日"/>
          </van-popup>
          <van-cell title="变更事项">
            <van-field clearable clear-trigger="always" :readonly="disable10" v-model="item.changeReason" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="变更前内容" :border="false" class="cellClass" />
          <van-field type="textarea" clearable clear-trigger="always"  :readonly="disable10" v-model="item.beforeContent" placeholder="请输入" style="text-align: right" rows="1" autosize/>
          <van-cell title="变更后内容" :border="false" class="cellClass" />
          <van-field type="textarea" clearable clear-trigger="always"  :readonly="disable10" v-model="item.afterContent" placeholder="请输入" style="text-align: right" rows="1" autosize/>
        </div>
      </van-cell-group>
    </div>
    <div class="check-info-wrap">
      <div class="check-title">选择信息</div>
      <div class="detail-info-wrap">
        <div class="info-item" :class="changeType === 1 ? 'checked' : ''" @click="changeCondition(1)">基础信息</div>
        <div class="info-item" :class="changeType === 2 ? 'checked' : ''" @click="changeCondition(2)">联系信息</div>
        <div class="info-item" v-if="!!dataForm.gridId" :class="changeType === 3 ? 'checked' : ''" @click="changeCondition(3)">网格信息</div>
        <div class="info-item" :class="changeType === 4 ? 'checked' : ''" @click="changeCondition(4)">注册登记信息</div>
        <div class="info-item" :class="changeType === 5 ? 'checked' : ''" @click="changeCondition(5)">税务信息</div>
        <div class="info-item" :class="changeType === 6 ? 'checked' : ''" @click="changeCondition(6)">参保信息</div>
        <div class="info-item" :class="changeType === 7 ? 'checked' : ''" @click="changeCondition(7)">变更信息</div>
      </div>
    </div>
    <grid-select v-show="gridShow" :list="list.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getbelongGrid, getDictTree, getbelongSubArea, getVirtualDict, getMiniGrid,listComRightLabel } from "@/utils/common";
import fieldView from '../../../components/field-view/field-view.vue';
import { getImageStream, formatLabel } from "@/utils/index";
import GridSelect from '../../userRes/grid-select.vue'
import { isNumber } from "@/utils/validate";
import {formatterDate} from '@/utils/utils'
let that
export default {
  components: {
    topBar,
    fieldView,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    if (this.$route.query.id) {
      (this.disable1 = true, this.disable2 = true, this.disable3 = true, this.disable4 = true,
          this.disable5 = true, this.disable6 = true, this.disable7 = true, this.disable8 = true, this.disable9 = true,this.disable10 = true), (this.dataForm.id = this.$route.query.id);
    }
    this.street = this.$globalData.userInfo.street
    this.initOrgId = this.$globalData.userInfo.orgId
    this.codeType = this.$globalData.userInfo.codeType
    this.isCodeShow = this.$globalData.userInfo.codeType == 2 ? true : false
    this.getInfo();
    that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.list.orgList = that.list.orgList.concat(e);
    });
    getbelongGrid({ orgId: this.$orgId }, function (e) {
      that.list.gridList = that.list.gridList.concat(e);
    });
    getDictTree({ code: "streetShopType" }, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    getVirtualDict('importantType', function (e) {
      that.list.importantTypeList = e
    })
    getVirtualDict('safetyPeril', function (e) {
      that.list.safetyPerilList = e
    })
    listComRightLabel(function (labels) {
      that.list.userCommunityLabelTree = labels
    }, 4)
    getVirtualDict('riskType', function (e) {
      that.list.riskLevelList = e
    })
  },
  data() {
    return {
      changeType: 1, // 切换信息
      firstVisible: true,
      secondVisible: true,
      fieldNames: { text: "label", value: "value", children: "children" },
      cascaderValue: [],
      disable1: false,
      disable2: false,
      disable3: false,
      disable4: false,
      disable5: false,
      disable6: false,
      disable7: false,
      disable8: false,
      disable9: false,
      disable10: false,
      typeShow: false,
      houseTypeShow: false,
      importantShow: false,
      importantTypeShow: false,
      trinityShow: false,
      categoryShow: false,
      subareaShow: false,
      buildingShow: false,
      hasLicenseShow: false,
      safetyPerilShow: false,
      scaleShow: false,
      hazardousShow: false,
      gridShow: false,
      hasHygieneShow: false,
      hasFoodShow: false,
      hasMedicalShow: false,
      hasDrugShow: false,
      operateBeginShow: false,
      operateEndShow: false,
      registerShow: false,
      moneySourceShow: false,
      payCheckShow: false,
      payerStatusShow: false,
      payCancelShow: false,
      signCancelShow: false,
      riskLevelShow: false,
      insuredStageShow: false,
      insuredStatusShow: false,
      codeShow: false,
      isCodeShow: false,
      isTipShow: false,
      tipValue: '',
      fileList: [],
      street: 0,
      initOrgId: 0,
      codeMappingId: 0,
      dataForm: {
        id: "",
        name: "",
        licenseName: "",
        type: "",
        contact: "",
        contactMobile: "",
        legal: "",
        legalMobile: "",
        address: "",
        community: "",
        subarea: "",
        buildingId: "1",
        licenseCode: "",
        content: '',
        imgUrl: "",
        newFileMessages: [],
        deleteFiles: [],
        gridId: 0,
        miniGridId: 0,
        category: '',
        areas: '',
        staffNum: 0,
        labels: [],
        importantType: '',
        safetyPeril: '',
        competentUnit: '',
        hygieneCode: '',
        gasNum: '',
        fireNum: '',
        hasHygiene: 1,
        hasFood: 1,
        hasDrug: 1,
        hasMedical: 1,
        enterpriseId: 0,
        code: '',
        buildingDepth: 0
      },
      extraInfo: {
        address: '', // 注册地址
        amount: '', // 开办资金
        operateBeginTime: '',// 经营开始期限
        operateEndTime: '',// 经营结束期限
        registerTime: '', // 注册日期
        registerCode: '', // 注册登记号
        moneySource: '', // 经济来源
        officeName: '', // 登记机关名称
        payer: '', // 纳税人名称
        payerCode: '', // 纳税人识别号
        payCheck: '', // 税务登记日期
        payOffice: '', // 税务登记机关
        payerStatus: '', // 纳税人状态
        payCancel: '', // 税务注销日期
        insuredName: '', // 参保单位名称
        insuredCode: '', // 参保单位编号
        riskLevel: '', // 社保行业风险类别
        insuredStage: '', // 参保缴费数据期别
        insuredStatus: '', // 参保缴费状态
        manageOffice: '', // 保险管理机关
        signCancelTime: '', // 注销登记日期
        signCancelReason: '', // 注销/吊销原因
      },
      label: false,
      labels: [],
      temporaryLabel: null,
      temporaryBuilding: null,
      urls: [],
      codeType: null,
      selectMsg: {
        placeTypeStr: "请选择",
        communityStr: "未知",
        subareaStr: "请选择",
        buildingStr: "无",
        hasLicenseStr: "否",
        gridName: '请选择',
        categoryStr: '请选择',
        importantTypeStr: '请选择',
        labelStr: '请选择',
        safetyPerilStr: '请选择',
        hasHygieneStr: '请选择',
        hasFoodStr: '请选择',
        hasMedicalStr: '请选择',
        hasDrugStr: '请选择',
        operateBeginTime: '',
        operateEndTime: '',
        registerTime: '',
        moneySourceStr: '请选择',
        payCheck: '',
        payerStatusStr: '请选择',
        payCancel: '',
        signCancelTime: '',
        riskLevel: '请选择',
        insuredStage: '',
        insuredStatus: '请选择',
        gridUsers: [],
        code: '',
        codeMappingEntity: {},
        communityName: '',
        subareaName: '',
        buildingName: '',
        codeStr: '请选择',
        codeName: ''
      },
      list: {
        orgList: [],
        gridList: [],
        placeTypeList: [],
        publicBuildingList: [],
        categoryList: [{value: 2, label: '企业'}, {value: 1, label: '个体工商户'}],
        importantTypeList: [],
        safetyPerilList: [],
        hasFoodList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        hasMedicalList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        hasDrugList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        moneySourceList: [{value: 1, label: '自有资金'}, {value: 2, label: '银行借贷资金'}, {value: 3, label: '预售款资金'}],
        payerStatusList: [{value: 0, label: '正常'}, {value: 1, label: '非正常'}], // 纳税人状态下拉
        riskLevelList: [], // 社保行业风险类别下拉
        insuredStatusList: [{value: 1, label: '参保缴费'}, {value: 2, label: '暂停缴费'}, {value: 3, label: '冻结'}], // 参保缴费状态下拉
        codeList: [],
      },
      changeList: [{
        changeDate: '',
        changeReason: '',
        beforeContent: '',
        afterContent: '',
        status: 0,
        changeDateShow: false
      }], //变更信息
      submitList: []
    };
  },
  methods: {
    getInfo() {
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/place/placeinfostreetshop/info/${this.dataForm.id}`),
          method: "post",
          params: this.$http.adornParams(),
        }).then(
            ({ data }) => {
              if (data.code == 0) {
                var placeLabels = data.labels
                var labels = []
                if (placeLabels != null) {
                  labels = formatLabel(placeLabels, true);
                }
                that.labels = labels
                let idsArray = labels.map((item) => {
                  item["delete"] = false
                  return item.ids
                })
                that.dataForm.labels = that.dataForm.labels.concat(idsArray)
                this.setInfoToForm(data.placeInfoStreetShop)
                this.getChangeInfo()
              }

              this.$toast.clear();
            },
            (err) => {
              this.$toast.clear();
            }
        );
      } else {
        // 自定义字段
        this.$nextTick(() => {
          this.$refs.fieldView.init(null, 4, this.$orgId)
        })
      }
    },
    getChangeInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/place/placeinfochange/list`),
        method: 'get',
        params: this.$http.adornParams({
          id: this.dataForm.id,
          type: 1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.changeList = data.list.length > 0 ? data.list.map(item => {
            return {
              ...item,
              id: item.id || undefined,
              recordId: this.dataForm.id || undefined,
              recordType: 2,
              changeReason: item.changeEvent,
              beforeContent: item.oldContent,
              afterContent: item.newContent,
              changeDateShow: false
            }
          }) : [{
            changeDate: '',
            changeReason: '',
            beforeContent: '',
            afterContent: '',
            status: 0,
            changeDateShow: false
          }]
          this.submitList = this.changeList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 根据营业执照号获取店铺信息
    getInfoByLicenseCode () {
      if (this.dataForm.licenseCode) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/place/placeinfostreetshop/getByLicenseCode`),
          method: 'get',
          params: this.$http.adornParams({
            'licenseCode': this.dataForm.licenseCode,
            'isOver': 1
          })
        }).then(({data}) => {
          if (data && data.code === 0 && data.placeInfoStreetShop) {
            if (data.placeInfoStreetShop.shopType == 1) {
              this.$toast.fail('已有此统一社会信用代码的个体工商户的店铺，请勿重复添加！')
            } else {
              this.$dialog.confirm({
                title: '提示',
                message: '统一社会信用代码已存在请选择操作',
                confirmButtonText: '导入',
                cancelButtonText: '取消'
              }).then(() => {
                this.setInfoToForm(data.placeInfoStreetShop)
              })
            }
          }
        })
      }
    },
    // 设置数据信息到控件
    setInfoToForm (data) {
      let orgName = ''
      if (this.initOrgId == this.street) {
        this.dataForm.community = data.community
        if (data.subarea) {
          orgName = data.communityName + '-' + data.subareaName
        } else {
          orgName = data.communityName
        }
        this.getBuildingList(data.community)
      } else {
        this.dataForm.subarea = data.subarea
        if (data.subarea) {
          orgName = data.communityName + '-' + data.subareaName
        } else {
          orgName = data.communityName
        }
        this.getBuildingList(data.subarea)
      }
      this.dataForm.id = data.id
      this.dataForm.type = data.type
      this.dataForm.staffNum = data.staffNum || 0
      this.dataForm.name = data.name
      this.dataForm.licenseName = data.onlineName
      this.dataForm.category = data.shopType
      this.dataForm.licenseCode = data.licenseCode
      // 管理网格
      if (data.gridId && !data.miniGridId) {
        this.dataForm.gridId = data.gridId
        this.dataForm.grid = data.gridId
      }

      if (data.miniGridId) {
        this.dataForm.gridId = data.gridId
        this.dataForm.miniGridId = data.miniGridId
        this.dataForm.grid = [data.gridId, data.miniGridId]
      }
      if (data.houseType == null || data.houseType === undefined) {
        this.dataForm.houseType = -1
      } else {
        this.dataForm.houseType = data.houseType
      }
      this.dataForm.street = data.street
      this.dataForm.community = data.community
      this.dataForm.buildingId = data.buildingId
      this.dataForm.contact = data.contact
      this.dataForm.contactMobile = data.contactMobile
      this.dataForm.legal = data.legal
      this.dataForm.legalMobile = data.legalMobile
      this.dataForm.hasLicense = data.hasLicense
      this.dataForm.hasFood = data.hasFood
      this.dataForm.hasDrug = data.hasDrug
      this.dataForm.hasMedical = data.hasMedical
      this.dataForm.medicalCode = data.medicalCode
      this.dataForm.foodCode = data.foodCode
      this.dataForm.drugCode = data.drugCode
      this.dataForm.licenseCode = data.licenseCode
      this.dataForm.hygieneCode = data.hygieneCode
      this.dataForm.important = data.important
      this.dataForm.address = data.address
      this.dataForm.remark = data.remark
      this.dataForm.imgUrl = data.imgUrl
      this.dataForm.trinity = data.trinity
      this.dataForm.gasNum = data.gasNum
      this.dataForm.fireNum = data.fireNum
      this.dataForm.level = data.level
      this.dataForm.safetyDirectorMobile = data.safetyDirectorMobile
      this.dataForm.safetyDirectorName = data.safetyDirectorName
      this.dataForm.importantType = data.importantType
      this.dataForm.safetyPeril = data.safetyPeril
      this.dataForm.areas = data.areas
      this.dataForm.competentUnit = data.competentUnit
      this.dataForm.isScaleCompany = data.isScaleCompany
      this.dataForm.isHazardousGoodsCompany = data.isHazardousGoodsCompany
      this.dataForm.purpose = data.purpose
      this.dataForm.code = data.code
      this.selectMsg.codeName = data.codeMappingEntity ? data.codeMappingEntity.fullName : ''
      this.codeMappingId = data.codeMappingEntity ? data.codeMappingEntity.id : 0
      this.selectMsg.codeStr = data.code
      this.dataForm.level = data.level
      this.dataForm.content = data.content
      this.dataForm.enterpriseId = data.enterpriseId
      let u;
      let urls = [];
      //获取图片流
      if (data.imgUrl) {
        let imgUrls = data.imgUrl.split(",");

        for (let i in imgUrls) {
          let u;
          let file = imgUrls[i];
          u = getImageStream(imgUrls[i]);
          let url = {
            uid: urls.length,
            status: "done", //uploading上传中，done上传完成，error上传失败
            url: u,
            fileType: "image/png",
            realUrl: file.replace(/\\/g, "/"),
          };
          urls.push(url);
        }
      }
      this.urls = urls;
      // 场所自定义字段
      this.$refs.fieldView.init(data.id, 4, data.community || data.street)
      let ancillaryInfo = data.ancillaryInfo || {}
      this.extraInfo = {
        id: ancillaryInfo.id,
        address: ancillaryInfo.registerAddr, // 注册地址
        amount: ancillaryInfo.registerCapital, // 开办资金
        operateBeginTime: ancillaryInfo.operatStart, // 经营期限
        operateEndTime: ancillaryInfo.operatEnd,
        registerTime: ancillaryInfo.registerDate, // 注册日期
        registerCode: ancillaryInfo.registerId, // 注册登记号
        moneySource: ancillaryInfo.economicSource, // 经济来源
        officeName: ancillaryInfo.registerAuthority, // 登记机关名称
        payer: ancillaryInfo.taxpayer, // 纳税人名称
        payerCode: ancillaryInfo.taxpayerId, // 纳税人识别号
        payCheck: ancillaryInfo.taxRegistrationDate, // 税务登记日期
        payOffice: ancillaryInfo.taxRegistrationAuthority, // 税务登记机关
        payerStatus: ancillaryInfo.taxpayerStatus, // 纳税人状态
        payCancel: ancillaryInfo.taxCancellationDate, // 税务注销日期
        insuredName: ancillaryInfo.insuredEntity, // 参保单位名称
        insuredCode: ancillaryInfo.insuredEntityId, // 参保单位编号
        riskLevel: ancillaryInfo.industryRiskType, // 社保行业风险类别
        insuredStage: ancillaryInfo.insurancePaymentDate, // 参保缴费数据期别
        insuredStatus: ancillaryInfo.insurancePaymentStatus, // 参保缴费状态
        manageOffice: ancillaryInfo.insuranceAuthority, // 保险管理机关
        signCancelTime: ancillaryInfo.cancellationDate, // 注销登记日期
        signCancelReason: ancillaryInfo.cancellationCause, // 注销/吊销原因
      }
      this.selectMsg = {
        subareaStr: orgName,
        placeTypeStr: data.typeName,
        categoryStr: data.shopTypeStr,
        gridName: data.gridName,
        gridUser: data.gridUser,
        codeMappingEntity: data.codeMappingEntity,
        code: data.code,
        communityName: data.communityName,
        subareaName: data.subareaName || '',
        buildingName: data.buildingName !== null ? data.buildingName + "幢" : '无',
        buildingStr: data.buildingName !== null ? data.buildingName + "幢" : '无',
        safetyPerilStr: data.safetyPerilStr,
        gridUsers: data.gridUsers,
        hasHygieneStr: data.hasHygiene == 1 ? '否' : '是',
        hasFoodStr: data.hasFood == 1 ? '否' : '是',
        hasDrugStr: data.hasDrug == 1 ? '否' : '是',
        hasMedicalStr: data.hasMedical == 1 ? '否' : '是',
        operateBeginTime: ancillaryInfo.operatStart,
        operateEndTime: ancillaryInfo.operatEnd,
        registerTime: ancillaryInfo.registerDate,
        moneySourceStr: ancillaryInfo.economicSource == 1 ? '自有资金' : (ancillaryInfo.economicSource == 2 ? '银行借贷资金' : (ancillaryInfo.economicSource == 3 ? '预售款资金' : '')),
        payCheck: ancillaryInfo.taxRegistrationDate,
        payerStatusStr: ancillaryInfo.taxpayerStatus == 0 ? '正常' : (ancillaryInfo.taxpayerStatus == 1 ? '非正常' : ''),
        payCancel: ancillaryInfo.taxCancellationDate,
        signCancelTime: ancillaryInfo.cancellationDate,
        riskLevel: ancillaryInfo.industryRiskTypeStr,
        insuredStage: ancillaryInfo.insurancePaymentDate,
        insuredStatus: ancillaryInfo.insurancePaymentStatus == 1 ? '参保缴费' : (ancillaryInfo.insurancePaymentStatus == 2 ? '暂停缴费' : (ancillaryInfo.insurancePaymentStatus == 3 ? '冻结' : ''))
      }
      var placeLabels = data.labelList
      var labels = []
      if (placeLabels != null) {
        labels = formatLabel(placeLabels, true);
      }
      that.labels = labels
      let idsArray = labels.map((item) => {
        item["delete"] = false
        return item.ids
      })
      that.dataForm.labels = that.dataForm.labels.concat(idsArray)
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/home/file/ocr'),
        method: 'post',
        headers: {
          appid: 'wxcd8dbbf3f2ba3b14',
          type: 2
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm.licenseCode = data.data.result
          this.getInfoByLicenseCode()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList(orgId) {
      if (this.street == this.initOrgId) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
          method: 'get',
          params: this.$http.adornParams({
            communityId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      } else {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/bySubareaIdTree'),
          method: 'get',
          params: this.$http.adornParams({
            subareaId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    changeCondition(type) {
      this.changeType = type
      if (type == 1) {
        // 自定义字段
        this.$nextTick(() => {
          this.$refs.fieldView.init(this.dataForm.id, 4, this.$orgId)
        })
      }
    },
    disable2Change(val) {
      this.disable2 = val
    },
    showlabel() {
      if (!this.disable3) {
        this.label = true
      }
    },
    labelOverlay() {
      let temporaryLabel = this.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }
      temporaryLabel["delete"] = false
      this.labels.push(temporaryLabel)
      this.dataForm.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = formatLabel(map, false)
      this.temporaryLabel = temporaryLabel
    },
    removeLabel(value) {
      for (let i in this.dataForm.labels) {
        let label = this.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == value) {
          this.labels[i]["delete"] = true
        }
      }
      this.dataForm.labels = this.dataForm.labels
      this.labels = this.labels
      this.$forceUpdate()
    },
    // 经营类型选择
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value) {
        this.selectMsg.placeTypeStr = value.label;
        this.dataForm.type = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.typeShow = false;
      }
    },
    // 所属小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.label;
        if (this.street == this.initOrgId) {
          this.dataForm.community = value.value;
        } else {
          this.dataForm.subarea = value.value;
        }
      }
      this.subareaShow = false;
      this.getBuildingList(value.value);
    },
    // 详细地址选择
    buildingFinish(value) {
      let options = value.selectedOptions
      this.selectMsg.buildingStr = '请选择'
      this.dataForm.buildingId = ''
      let option = options[options.length - 1];
      if (option.depth !== 1 && option.depth !== 3) {
        let map = {}
        map["value"] = option.value
        map['label'] = option.label
        this.temporaryBuilding = map
        let type = option.depth == 2 ? 1 : 2
        this.dataForm.buildingDepth = option.depth
        this.getCodeById(option.value, type)
      }
    },
    buildingOverlay() {
      if (this.temporaryBuilding && this.temporaryBuilding.value !== '') {
        this.selectMsg.buildingStr = this.temporaryBuilding.label
        this.dataForm.buildingId = this.temporaryBuilding.value
      }
    },
    // 所属楼幢选择
    buildingConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = value.label;
        this.dataForm.buildingId = value.value;
      }
      this.buildingShow = false;
    },
    // 店铺类型
    categoryConfirm (value) {
      if (value) {
        this.selectMsg.categoryStr = value.label;
        this.dataForm.category = value.value;
      }
      this.categoryShow = false;
    },
    importantTypeConfirm(value) {
      if (value) {
        this.selectMsg.importantTypeStr = value.label;
        this.dataForm.importantType = value.value;
      }
      this.importantTypeShow = false;
    },
    safetyPerilConfirm (value) {
      if (value) {
        this.selectMsg.safetyPerilStr = value.label;
        this.dataForm.safetyPeril = value.value;
      }
      this.safetyPerilShow = false;
    },
    hasHygieneConfirm (value) {
      if (value) {
        this.selectMsg.hasHygieneStr = value.label;
        this.dataForm.hasHygiene = value.value;
      }
      this.hasHygieneShow = false;
    },
    hasFoodConfirm (value) {
      if (value) {
        this.selectMsg.hasFoodStr = value.label;
        this.dataForm.hasFood = value.value;
      }
      this.hasFoodShow = false;
    },
    hasMedicalConfirm (value) {
      if (value) {
        this.selectMsg.hasMedicalStr = value.label;
        this.dataForm.hasMedical = value.value;
      }
      this.hasMedicalShow = false;
    },
    hasDrugConfirm (value) {
      if (value) {
        this.selectMsg.hasDrugStr = value.label;
        this.dataForm.hasDrug = value.value;
      }
      this.hasDrugShow = false;
    },
    operateBeginConfim (value) {
      let d = new Date(this.extraInfo.operateEndTime)
      if (this.extraInfo.operateEndTime && this.extraInfo.operateEndTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.extraInfo.operateBeginTime = formatterDate(value)
      this.selectMsg.operateBeginTime = formatterDate(value)
      this.operateBeginShow = false
    },
    operateEndConfim (value) {
      let d = new Date(this.extraInfo.operateBeginTime)
      if (this.extraInfo.operateBeginTime && this.extraInfo.operateBeginTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.extraInfo.operateEndTime = formatterDate(value)
      this.selectMsg.operateEndTime = formatterDate(value)
      this.operateEndShow = false
    },
    registerConfim (value) {
      this.extraInfo.registerTime = formatterDate(value)
      this.selectMsg.registerTime = formatterDate(value)
      this.registerShow = false
    },
    moneySourceConfirm (value) {
      if (value) {
        this.selectMsg.moneySourceStr = value.label;
        this.extraInfo.moneySource = value.value;
      }
      this.moneySourceShow = false;
    },
    payCheckConfim (value) {
      this.extraInfo.payCheck = formatterDate(value)
      this.selectMsg.payCheck = formatterDate(value)
      this.payCheckShow = false
    },
    payerStatusConfirm (value) {
      if (value) {
        this.selectMsg.payerStatusStr = value.label;
        this.extraInfo.payerStatus = value.value;
      }
      this.payerStatusShow = false;
    },
    payCancelConfim (value) {
      this.extraInfo.payCancel = formatterDate(value)
      this.selectMsg.payCancel = formatterDate(value)
      this.payCancelShow = false
    },
    signCancelConfim (value) {
      this.extraInfo.signCancelTime = formatterDate(value)
      this.selectMsg.signCancelTime = formatterDate(value)
      this.signCancelShow = false
    },
    riskLevelConfirm (value) {
      if (value) {
        this.selectMsg.riskLevel = value.label;
        this.extraInfo.riskLevel = value.value;
      }
      this.riskLevelShow = false;
    },
    insuredStageConfim (value) {
      this.extraInfo.insuredStage = formatterDate(value)
      this.selectMsg.insuredStage = formatterDate(value)
      this.insuredStageShow = false
    },
    insuredStatusConfirm (value) {
      if (value) {
        this.selectMsg.insuredStatus = value.label;
        this.extraInfo.insuredStatus = value.value;
      }
      this.insuredStatusShow = false;
    },
    addInfo () {
      let addInfo = {
        id: 0,
        changeDate: '',
        changeReason: '',
        beforeContent: '',
        afterContent: '',
        status: 0,
        changeDateShow: false
      }
      this.changeList.push(addInfo)
      this.submitList = this.changeList
    },
    deleteClick (id, index) {
      let change = this.changeList
      change.map(item => {
        if (item.id == id) {
          item.status = 1
        }
      })
      this.changeList = this.changeList.filter((item, idx) => { return index !== idx })
      this.submitList = change
    },
    changeDateConfim (value, idx){
      let change = this.changeList
      change.map((item, index) => {
        if (index == idx) {
          item.changeDate = formatterDate(value)
          item.changeDateShow = false
        }
      })
      this.changeList = change
      this.submitList = change
    },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = 0
      this.dataForm.miniGridId = 0
      const {selectList, labelCondition, depth} = e
      this.selectMsg.gridName = labelCondition[0].label;
      this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
      this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      this.gridShow = false
    },
    codeConfirm (value){
      if (value) {
        this.selectMsg.codeStr = value.value;
        this.dataForm.code = value.value;
        this.selectMsg.codeName = value.label
        this.dataForm.address = value.label
        this.list.codeList.map(item => {
          if (item.value == value.value) {
            this.codeMappingId = item.codeMappingId
          }
        })
      }
      this.codeShow = false;
    },
    getCode() {
      this.list.codeList = []
      this.selectMsg.codeStr = '请选择'
      this.dataForm.code = ''
      this.isTipShow = false
      this.tipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let name = true
      if (isNumber(this.selectMsg.codeName)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? this.selectMsg.codeName : undefined,
          code: name ? undefined : this.selectMsg.codeName,
          type: 4
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (name) {
            this.list.codeList = list
          } else {
            this.list.codeList = list
          }
          this.codeShow = true
        } else {
          this.isTipShow = true
          this.tipValue = data.msg
          // this.$toast.fail(data.msg)
        }
      })
    },
    getCodeById (id, type) {
      // 1 楼幢, 2 房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/addressCodeMapping/infoByIdAndType`),
        method: 'get',
        params: this.$http.adornParams({
          id: id,
          type: type
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.one) {
            if (type == 1) {
              this.dataForm.code = data.one.buildingCode
              this.selectMsg.codeStr = data.one.buildingCode
            } else {
              this.dataForm.code = data.one.houseCode
              this.selectMsg.codeStr = data.one.houseCode
            }
            this.selectMsg.codeName = data.one.fullName
            this.codeMappingId = data.one.id
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 删除图片
    uploaddel(file, detail) {
      //debugger;
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", `files/place/${this.$orgId}`);
        this.$http({
          url: this.$http.adornUrl("/wxapp/file/upload"),
          method: "post",
          data: formdata,
        }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            console.log(this.urls);
            // let uid = that.urls.length + 1;
            // let u = getImageStream(data.fileMessage.relativePath);
            // let url = {
            //   uid: uid,
            //   status: "done",
            //   url: u,
            // };
            // that.urls.push(url);
            that.dataForm.imgUrl = that.dataForm.imgUrl
                ? that.dataForm.imgUrl + "," + data.fileMessage.relativePath
                : data.fileMessage.relativePath;

            // this.dataForm.imgUrl += ','+data.fileMessage.relativePath;

            // this.dataForm.newFileMessages.push(data.fileMessage);
            return true;
          } else {
            that.$toast.fail(data.msg);
          }
        });
      })
    },
    async submit() {
      let url = "/wxapp/place/placeinfostreetshop/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        url = "/wxapp/place/placeinfostreetshop/update";
      }
      if (this.dataForm.subarea === '' && this.dataForm.community === '') {return this.$toast.fail('失败:请选择小区');}
      if (this.dataForm.name === '') {return this.$toast.fail('失败:实体店名不能为空');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:经营类型不能为空');}
      if (this.dataForm.contact === '') {return this.$toast.fail('失败:负责人不能为空');}
      if (this.dataForm.contactMobile === '') {return this.$toast.fail('失败:负责人联系方式不能为空');}
      if (this.dataForm.content === '') {return this.$toast.fail('失败:经营内容不能为空');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:详细地址不能为空');}
      // 自定义字段参数
      let extendInfoList = await this.$refs.fieldView.submitForm()
      let buildingDepth = null
      if (this.dataForm.buildingDepth == 2) {
        buildingDepth = 1
      } else if (this.dataForm.buildingDepth == 4) {
        buildingDepth = 3
      }
      this.dataForm.buildingDepth = buildingDepth
      let data = {
        'id': this.dataForm.id || undefined,
        'type': this.dataForm.type,
        'name': this.dataForm.name,
        'community': this.dataForm.community || 0,
        'street': this.dataForm.street || 0,
        'subarea': this.dataForm.subarea || 0,
        'houseType': this.dataForm.houseType,
        'buildingId': this.dataForm.buildingId || 0,
        'contact': this.dataForm.contact,
        'contactMobile': this.dataForm.contactMobile,
        'legal': this.dataForm.legal,
        'legalMobile': this.dataForm.legalMobile,
        'hasLicense': this.dataForm.hasLicense,
        'hasHygiene': this.dataForm.hasHygiene,
        'hasFood': this.dataForm.hasFood,
        'hasDrug': this.dataForm.hasDrug,
        'hasMedical': this.dataForm.hasMedical,
        'medicalCode': this.dataForm.medicalCode,
        'foodCode': this.dataForm.foodCode,
        'drugCode': this.dataForm.drugCode,
        'licenseCode': this.dataForm.licenseCode,
        'onlineName': this.dataForm.licenseName,
        'content': this.dataForm.content,
        'hygieneCode': this.dataForm.hygieneCode,
        'level': this.dataForm.level,
        'important': this.dataForm.important,
        'address': this.dataForm.address,
        'remark': this.dataForm.remark,
        'imgUrl': this.dataForm.imgUrl,
        'trinity': this.dataForm.trinity,
        'shopType': this.dataForm.category,
        'gasNum': this.dataForm.gasNum,
        'fireNum': this.dataForm.fireNum,
        'areas': this.dataForm.areas,
        'isScaleCompany': this.dataForm.isScaleCompany,
        'isHazardousGoodsCompany': this.dataForm.isHazardousGoodsCompany,
        'safetyDirectorName': this.dataForm.safetyDirectorName,
        'safetyDirectorMobile': this.dataForm.safetyDirectorMobile,
        'importantType': this.dataForm.importantType,
        'safetyPeril': this.dataForm.safetyPeril,
        'competentUnit': this.dataForm.competentUnit,
        'purpose': this.dataForm.purpose,
        'staffNum': this.dataForm.staffNum,
        'labels': this.dataForm.labels.toString(),
        'gridId': this.dataForm.gridId || 0,
        'miniGridId': this.dataForm.miniGridId ? this.dataForm.miniGridId : 0,
        'enterpriseId': this.dataForm.enterpriseId,
        'buildingDepth': this.dataForm.buildingDepth
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: {...data, extendInfoList, labels: this.dataForm.labels.join(','), codeMappingId: this.codeMappingId || undefined},
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("新增成功");
          this.disable1 = true
          this.disable2 = true
          this.disable3 = true
          this.disable4 = true
          this.disable5 = true
          this.disable6 = true
          this.getInfo()
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    submitExtra() {
      let placeAncillaryInfo = {
        id: this.extraInfo.id || undefined,
        recordId: this.dataForm.id,
        recordType: 1,
        registerAddr: this.extraInfo.address,
        registerCapital: this.extraInfo.amount,
        operatStart: this.extraInfo.operateBeginTime,
        operatEnd: this.extraInfo.operateEndTime,
        registerDate: this.extraInfo.registerTime,
        registerId: this.extraInfo.registerCode,
        economicSource: this.extraInfo.moneySource,
        registerAuthority: this.extraInfo.officeName,
        taxpayer: this.extraInfo.payer,
        taxpayerId: this.extraInfo.payerCode,
        taxRegistrationDate: this.extraInfo.payCheck,
        taxRegistrationAuthority: this.extraInfo.payOffice,
        taxpayerStatus: this.extraInfo.payerStatus,
        taxCancellationDate: this.extraInfo.payCancel,
        insuredEntity: this.extraInfo.insuredName,
        insuredEntityId: this.extraInfo.insuredCode,
        industryRiskType: this.extraInfo.riskLevel,
        insurancePaymentDate: this.extraInfo.insuredStage,
        insurancePaymentStatus: this.extraInfo.insuredStatus,
        insuranceAuthority: this.extraInfo.manageOffice,
        cancellationDate: this.extraInfo.signCancelTime,
        cancellationCause: this.extraInfo.signCancelReason,
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/place/placeancillaryinfo/update'),
        method: 'post',
        data: {...placeAncillaryInfo},
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '操作成功',
            duration: 2000,
            onClose: () => {
              this.disable7 = true
              this.disable8 = true
              this.disable9 = true
              this.getInfo()
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    submitChange() {
      let submitList = this.submitList.map(item => {
        return {
          id: item.id || undefined,
          recordId: this.dataForm.id || undefined,
          recordType: 1,
          changeDate: item.changeDate,
          changeEvent: item.changeReason,
          oldContent: item.beforeContent,
          newContent: item.afterContent,
          status: item.status
        }
      })
      this.$http({
        url: this.$http.adornUrl('/wxapp/place/placeinfochange/save'),
        method: 'post',
        data: this.$http.adornData(submitList, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '操作成功',
            duration: 2000,
            onClose: () => {
              this.disable10 = true
              this.getChangeInfo()
              this.getInfo()
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.border-content {
  padding-bottom: 380px;
}
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.placeInfo {
  color: #fff;
  .top {
    position: relative;
    height: 320px;
    background: url('~@/assets/img/placeInfo.png') no-repeat center;
    background-size: 100% 100%;
    p {
      width: 100%;
      position: absolute;
      top: 24px;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
    }
    .content {
      position: absolute;
      top: 90px;
      left: 44px;
      line-height: 40px;
      .van-col:first-child {
        font-size: 26px;
      }
    }
  }
  .topInfo {
    position: relative;
    top: 24px;
    margin: 0 30px;
    height: 90px;
    background: linear-gradient(225deg, #7BB7FC 0%, #4581F8 100%);
    box-shadow: 0px 0px 24px 0px rgba(113,134,177,0.5);
    border-radius: 12px 12px 0px 0px;
    p {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .bottom {
    position: relative;
    top: -20px;
    margin: 0 25px;
    height: 78px;
    padding: 16px 0 18px 24px;
    background: #fff;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0 0 80px -3px rgba(140, 172, 236, 0.75);
    .grid {
      color: #666666;
      font-size: 28px;
      margin-left: 24px;
      i {
        font-weight: 600;
        font-style: normal;
      }
    }
  }
  .bottomInfo {
    position: relative;
    top: 10px;
    margin: 0 30px 52px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    background: #fff;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0 0 80px -3px rgba(140, 172, 236, 0.75);
    .grid {
      color: #666666;
      font-size: 28px;
      //margin-left: 24px;
      i {
        font-weight: 600;
        font-style: normal;
      }
    }
  }
}
.gridBtn {
  position: relative;
  //line-height: 66px;
  //::v-deep .van-icon-arrow{
  //  position: absolute;
  //  right: 160px;
  //}
  .van-button {
    position: absolute;
    right: 0;
    width: 116px;
    height: 58px;
    color: #4581F8;
    font-size: 30px;
    background: transparent;
    border-radius: 8px;
    border: 2px solid #4581F8;
  }
}
.gridUser {
  .tel {
    width: 40px;
    height: 40px;
  }
}
.info {
  ::v-deep .van-radio-group{
    float: unset !important;
  }
}
.button-right {
  float: right;
  position: absolute;
  right: 20px;
  top: 5px;
  .van-button {
    padding: 2px 24px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid #4581F8;
    color: #4581F8;
  }
}
.licenseCode {
  ::v-deep .van-cell__value {
    line-height: 15px;
  }
  ::v-deep .van-field__right-icon {
    transform: translateY(6px);
  }
}
</style>
